<template>
  <div class="main-card card mb-3" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
      {{ $t('customers.lang_createProject') }}
    </div>
    <v-divider class="ma-0 pa-0"/>
    <v-form lazy-validation ref="form" v-model="valid">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :error="error"
                          :error-messages="errorMessages"
                          :label="$t('customers.lang_projectNumber')"
                          :rules="[rules.required]"
                          @focus="showTouchKeyboard"
                          autocomplete="off"
                          min="1"
                          outlined
                          ref="numInput"
                          type="number"
                          v-model="projectNumber"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :label="$t('customers.lang_projectName')"
                          :rules="[rules.required]"
                          @focus="showTouchKeyboard"
                          autocomplete="off"
                          outlined
                          required
                          v-model="projectName"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <date-picker hint="dd/mm/yyyy Format"
                         :label="$t('generic.lang_outputReport')"
                         v-model="startDate"
            />
          </v-col>


          <v-col cols="12">
            <v-text-field :rules="[ v => !!v]" :label="$t('customers.lang_projectPrice')" outlined type="number" v-model="projectPrice"/>
          </v-col>

          <v-col cols="12">
            <v-textarea dense :label="$t('generic.lang_note') + ' ...'" outlined v-model="projectNote"/>
          </v-col>
          <v-col cols="12">
            <strong>{{ $t('erp.lang_warecreate_color') }}:</strong>
            <swatches
                inline v-model="color"
                background-color="transparent"
            ></swatches>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn :disabled="!valid"
                 :loading="loading"
                 @click="create()"
                 color="primary"
                 large
          >
            {{ $t('generic.lang_add') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixins/KeyboardMixIns";
import {Events} from "../../plugins/events";
import swatches from "vue-swatches";
import DatePicker from "../common/datepicker"

export default {
  name: "CreateCustomerProjectComponent",
  components: {
    swatches, DatePicker
  },
  mixins: [mixin],
  data() {
    return {
      customerID: null,
      valid: true,
      loading: false,
      projectName: null,
      projectPrice: null,
      projectNote: null,
      error: false,
      errorMessages: null,
      projectNumber: null,
      startDate: null,
      color: '#555',
      rules: {
        required: (val) => {
          return !!val || this.$t('generic.lang_requiredField')
        },
      }
    }
  },
  watch: {
    async projectNumber() {
      this.axios.post('get/customer/checkDupCustomerProjectNumber/', {
        projectNumber: parseInt(this.projectNumber),
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          this.error = false;
          this.errorMessages = "";
        } else {
          this.error = true;
          this.errorMessages = res.data.msg;
        }
      })
    }
  },
  /*
  * get/customer/checkDupCustomerProjectNumber/

  Post projectNumber
  returns status true if not duplicate and status  => false, msg =>"duplicate entry" if entyr is duplicated

  * */

//RETURNS status SUCCESS OR FAILED WITH MSG*/
  methods: {
    create() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      let timestamp = (this.startDate === null) ? parseInt((Date.now() / 1000).toFixed(0)) : (new Date(self.startDate)).getTime() / 1000
      this.loading = true;

      this.axios.post('create/customer/customerProject/', {
        ascCustomerID: parseInt(self.customerID),
        projectNumber: self.projectNumber,
        projectName: self.projectName,
        projectColor: self.color,
        projectTimestamp: timestamp,
        projectPrice: this.projectPrice,
        projectNote: this.projectNote
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.$refs.form.reset();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + " ",
            color: "error"
          });
        }
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false
      });
    },

  },
  mounted() {
    this.customerID = this.$route.params.id;
  //  ////console.log('mounted')
  }
}
</script>

<style scoped>

</style>
