<template>
    <div>
        <page-title :heading="$t('customers.lang_createProject')" show-previous-button :subheading="$t('customers.lang_createProject')" :icon=icon></page-title>
        <div class="app-main__inner">
            <CreateCustomerProjectComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import CreateCustomerProjectComponent from "../../components/customers/CreateCustomerProjectComponent";

    export default {
        name: "CreateCustomerProject",
        components: {
            CreateCustomerProjectComponent,
            PageTitle,
        },
        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>